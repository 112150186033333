import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asapScheduler, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';
import { map, take } from 'rxjs/operators';
import { DepartmentNameWithMemberCount } from '@shared/models/department.model';

type DepartmentDropdownItem = DropdownItem<
  number,
  DepartmentNameWithMemberCount
>;

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  allDepartmentDropdownCache: DepartmentDropdownItem[] = [];

  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown<Value = number, Context = void>(
    params: any,
  ): Observable<{
    [dropdownName: string]: DropdownItem<Value, Context>[];
  }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem<Value, Context>[];
    }>(API_URL.dropdown, params);
  }

  getAllDepartmentDropdown(
    canUseCache: any = false,
  ): Observable<DepartmentDropdownItem[]> {
    const cacheValue = this.allDepartmentDropdownCache;
    if (canUseCache && cacheValue.length) {
      return new Observable((subscriber) => {
        asapScheduler.schedule(() => {
          subscriber.next(cacheValue);
        });
      }).pipe(take<DepartmentDropdownItem[] | any>(1));
    }
    return this.getDropdown({
      type: 'department',
      is_all: true,
    }).pipe(
      map((data) => {
        // store certificate status into cache.
        this.allDepartmentDropdownCache = data[
          'department'
        ] as DepartmentDropdownItem[];
        return this.allDepartmentDropdownCache;
      }),
    );
  }

  getDropdownWithHeader(header: any, params: any): any {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem() {
    return [
      {
        label: 'External',
        value: 'external',
        context: {
          display_name: 'แบบขออนุมัติภายนอก',
          display_name_en: 'External',
        },
      },
      {
        label: 'Internal',
        value: 'internal',
        context: {
          display_name: 'แบบขออนุมัติภายใน',
          display_name_en: 'Internal',
        },
      },
      {
        label: 'Purchase Request',
        value: 'purchase_request',
        context: {
          display_name: 'แบบขออนุมัติการซื้อ',
          display_name_en: 'Purchase Request',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
    ];
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
