import { BreakpointObserver } from '@angular/cdk/layout';
import {
  inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DeviceType } from '@shared/models/common.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';
import * as d3 from 'd3-color';
import { HSLColor } from 'd3-color';

export interface ThemeList {
  id: number;
  active: boolean;
  // New theme color schema
  logo_round: string;
  logo_rec: string;
  primary_color: string;
  secondary_color: string;
  default: number;
  // TODO: Mock data to be delete
  name: string;
  // Logo
  display_email: string;
  display_pdf: string;
  display_login: string;
  display_sidebar: string;
  // Login
  bg_login_color: string;
  bg_login_second_color: string;
  box_login_color: string;
  text_login_color: string;
  text_box_login_color: string;
  button_login_color: string;
  text_button_login_color: string;
  // Theme
  bg_color_primary: string;
  bg_color_secondary: string;
  text_color: string;
  hovered_text_color: string;
  header_table_color: string;
  text_header_table_color: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public sourceColors: { [k: string]: HSLColor };
  public hslStdColorSets: HslColorSet[] = [
    {
      name: 'std-color-primary', // * primary default
      source: 'primary',
      saturation: 0,
      lightness: 0,
    },
    {
      name: 'std-color-secondary', // * secondary default
      source: 'secondary',
      saturation: 0,
      lightness: 0,
    },
    {
      name: 'std-color-1',
      source: 'primary',
      saturation: -51, // 49%
      lightness: 29, // 79%
    },
    {
      name: 'std-color-2',
      source: 'primary',
      saturation: -53, // 47%
      lightness: 43, // 93%
    },
    {
      name: 'std-color-3',
      source: 'primary',
      saturation: 0, // 100%
      lightness: -17, // 33%
    },
    {
      name: 'std-color-4',
      source: 'secondary',
      saturation: 0, // 100%
      lightness: 47.06, // 52.94%
    },
    {
      name: 'std-color-5', //text
      source: 'primary',
      saturation: -100, // 0%
      lightness: 50, // 100%
    },
    {
      name: 'std-color-6', //primary hover
      source: 'primary',
      saturation: -43, // 57%
      lightness: -4, // 46%
    },
    {
      name: 'std-color-7', //primary pressed
      source: 'primary',
      saturation: 0, // 100%
      lightness: -32, // 18%
    },
    {
      name: 'std-color-8', //secondary default
      source: 'secondary',
      saturation: 0, // 100%
      lightness: 3, // 53%
    },
    {
      name: 'std-color-9', //secondary hover
      source: 'secondary',
      saturation: 0, // 100%
      lightness: 10, // 60%
    },
    {
      name: 'std-color-10', //secondary pressed
      source: 'secondary',
      saturation: 0, // 100%
      lightness: -15, // 35%
    },
    {
      name: 'std-color-11', // base background
      source: 'primary',
      saturation: -60, // 40%
      lightness: 48, // 98%
    },
    {
      name: 'std-color-12', // highlight background
      source: 'primary',
      saturation: -50, // 50%
      lightness: -48, // 98%
    },
  ];
  themeConfigs: ThemeList | any;
  private setTheme = new BehaviorSubject<ThemeList | any>(null);
  data = this.setTheme.asObservable();

  private renderer: Renderer2;
  private rendererFactory = inject(RendererFactory2);

  constructor(
    private http: ApiService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  appendDynSelectColorCssVars(cssText: string): string {
    const primHsl = this.sourceColors['primary'].copy();
    const secHsl = this.sourceColors['secondary'].copy();
    // Add bg color variable for select
    const bgHsl = this.getHslColorSetValue({
      name: 'std-select-active-bg',
      source: 'primary',
      lightness: 95,
      interpretMode: 'absolute',
    });
    cssText += `--std-select-active-bg: ${bgHsl}; `;
    // Add text color variable for select
    cssText += `--std-select-active-text: ${
      primHsl.l > 0.75 ? '0 0% 0%' : this.getHslValue(primHsl)
    }; `;
    cssText += `--std-select-hover-text: ${
      primHsl.l > 0.75 ? '0 0% 0%' : this.getHslValue(primHsl)
    }; `;
    // Add bg color variable for select for secondary
    const bgSecHsl = this.getHslColorSetValue({
      name: 'std-select-active-bg-secondary',
      source: 'secondary',
      lightness: 95,
      interpretMode: 'absolute',
    });
    cssText += `--std-select-active-bg-secondary: ${bgSecHsl}; `;
    // Add text color variable for select for secondary
    cssText += `--std-select-active-text-secondary: ${
      secHsl.l > 0.75 ? '0 0% 0%' : this.getHslValue(secHsl)
    }; `;
    cssText += `--std-select-hover-text-secondary: ${
      secHsl.l > 0.75 ? '0 0% 0%' : this.getHslValue(secHsl)
    }; `;
    return cssText;
  }

  appendDynButtonColorCssVars(cssText: string): string {
    Object.keys(this.sourceColors).forEach((name) => {
      const sourceHsl = this.sourceColors[name].copy();
      const newTextHsl = sourceHsl.copy();
      if (sourceHsl.l > 0.75) {
        newTextHsl.l = sourceHsl.l >= 1 ? 0 : 0.5;
      } else {
        newTextHsl.l = 1;
      }
      cssText += `--std-btn-hover-bg-${name}: hsl(${this.getHslValue(
        sourceHsl,
      )}, ${sourceHsl.l > 0.75 ? 1 : 0.2}); `;
      cssText += `--std-btn-text-${name}: hsl(${this.getHslValue(
        newTextHsl,
      )}); `;
      cssText += `--std-btn-text-${name}-hover: hsl(${this.getHslValue(
        newTextHsl,
      )}, ${sourceHsl.l > 0.75 ? 0.5 : 1}); `;
      cssText += `--std-btn-border: ${
        sourceHsl.l > 0.75 ? '0 0% 92%' : this.getHslValue(sourceHsl)
      }; `;
      const activeBorderHsl = sourceHsl.copy({
        l: sourceHsl.l > 0.75 ? 0.5 : sourceHsl.l,
      });
      cssText += `--std-btn-active-outline-${name}: hsl(${this.getHslValue(
        activeBorderHsl,
      )}, ${sourceHsl.l > 0.75 ? 0.5 : 0.8}); `;
    });
    return cssText;
  }

  appendDynCheckMarkCssVars(cssText: string): string {
    const sourceHsl = this.sourceColors['secondary'].copy();
    const newHsl = sourceHsl.copy();
    if (sourceHsl.l > 0.75) {
      newHsl.l = sourceHsl.l >= 1 ? 0 : 0.5;
    } else {
      newHsl.l = 1;
    }
    const color = this.getHslValue(newHsl);
    cssText +=
      `--std-checkmark-url: url("data:image/svg+xml,%3csvg ` +
      `id='checkmark' width='20' height='20' viewBox='0 0 24 24' stroke='hsl(${color})' ` +
      `fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5 13L9 17L19 7' ` +
      `stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e"); `;
    cssText +=
      `--std-checkmark-indeterminate-url: url("data:image/svg+xml,%3csvg ` +
      `id='checkmark-indeterminate' width='20' height='20' viewBox='0 0 20 20' ` +
      `fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3e%3crect id='rect3' width='15' ` +
      `height='2' x='2.5' y='9' fill='hsl(${color})' fill-opacity='1' stroke-width='1' ry='1' ` +
      `stroke-dasharray='none' /%3e%3c/svg%3e"); `;
    return cssText;
  }

  appendDynToggleCssVars(cssText: string): string {
    const sourceHsl = this.sourceColors['secondary'].copy();
    const activeBorderHsl = sourceHsl.copy({
      ...sourceHsl,
      l: sourceHsl.l > 0.75 ? 0.5 : sourceHsl.l,
    });
    cssText += `--std-toggle-active-bg: ${this.getHslValue(
      activeBorderHsl,
    )}; `;
    return cssText;
  }

  applyColorsToCssVar(): void {
    let cssText = '';
    Object.keys(this.sourceColors).forEach((name) => {
      cssText += `--hue-${name}: ${this.sourceColors[name].h || 0}; `;
    });
    this.hslStdColorSets.forEach((color) => {
      const d3Hsl = this.interpretHslColorSet(color);
      cssText += `--${color.name}: ${this.getHslValue(d3Hsl)};`;
      const lightness = Math.round(d3Hsl.l * 10000) / 100;
      // Create text color for contrast to background
      if (lightness < 50) {
        cssText += `--text-bg-${color.name}: #fff;`;
      } else {
        cssText += `--text-bg-${color.name}: --${color.name};`;
      }
    });
    cssText = this.appendDynSelectColorCssVars(cssText);
    cssText = this.appendDynButtonColorCssVars(cssText);
    cssText = this.appendDynCheckMarkCssVars(cssText);
    cssText = this.appendDynToggleCssVars(cssText);
    const styleEl = this.renderer.createElement('style');
    styleEl.setAttribute('id', 'std-theme-color-variables');
    styleEl.innerHTML = `:root{${cssText}}`;
    this.insertCssTag('std-theme-color-variables', styleEl);
  }

  applyUtilStyleClasses(): void {
    let bgCssText = '';
    let textColorCssText = '';
    let textBgColorCssText = '';
    this.hslStdColorSets.forEach((color) => {
      bgCssText += `.bg-${color.name}{background-color: hsl(var(--${color.name}));} `;
      textColorCssText += `.text-${color.name}{color: hsl(var(--${color.name}));} `;
      textBgColorCssText += `.text-bg-${color.name}{color: hsl(var(--text-bg-${color.name}));} `;
    });
    const styleEl = this.renderer.createElement('style');
    styleEl.setAttribute('id', 'std-theme-color-utils');
    styleEl.innerHTML =
      bgCssText + textColorCssText + textBgColorCssText;
    this.insertCssTag('std-theme-color-utils', styleEl);
  }

  interpretHslColorSet(hslColorSet: HslColorSet): HSLColor {
    const hsl = this.sourceColors[hslColorSet.source].copy();
    let newH = hsl.h || 0;
    let newS = hsl.s * 100;
    let newL = hsl.l * 100;
    if (hslColorSet?.interpretMode === 'absolute') {
      newH = hslColorSet.hue || newH;
      newS = hslColorSet.saturation || newS;
      newL = hslColorSet.lightness || newL;
    } else {
      newH += hslColorSet.hue || 0;
      newS += hslColorSet.saturation || 0;
      newL += hslColorSet.lightness || 0;
    }
    return hsl
      .copy({
        h: Math.round(newH),
        s: Math.round(newS) / 100,
        l: Math.round(newL) / 100,
      })
      .clamp();
  }

  public fetchData(): Observable<ThemeList[]> {
    return this.http.get(API_URL.themes);
  }

  public loadTheme(id: number): Observable<ThemeList> {
    return this.http.get(API_URL.themes + id + '/');
  }

  public updateTheme(theme: any, id: number) {
    return this.http.patch(API_URL.themes + id + '/', theme);
  }

  public createTheme(theme: any) {
    return this.http.post(API_URL.themes, theme);
  }

  public deleteTheme(id: any) {
    return this.http.delete(API_URL.themes + id + '/');
  }

  public removeEmailLogo(id: any) {
    return this.http.delete(API_URL.themes + id + '/logo-email/');
  }

  /**
   * @param name A color name Ex. `std-color-1`
   */
  getHslColorSetByName(name: string): HslColorSet | undefined {
    return this.hslStdColorSets.find((color) => color.name === name);
  }

  /**
   * Example:
   * ```typescript
   * const hslColorSet: HslColorSet = {
   *   name: 'std-color-1',
   *   source: 'primary',
   *   saturation: '49%',
   *   lightness: '79%',
   * };
   * getHslColorValue(hslColorSet); // 173, 49%, 79%
   * ```

   * @param hslColorSet
   * @returns the HSL color value example: 173, 49%, 79%
   */
  getHslColorSetValue(hslColorSet: HslColorSet): string {
    const hsl = this.interpretHslColorSet(hslColorSet);
    return this.getHslValue(hsl);
  }

  getHslValue(d3Hsl: HSLColor): string {
    const saturation = d3Hsl.s * 100;
    const lightness = d3Hsl.l * 100;
    const hslColor =
      `${d3Hsl.h || 0},` +
      ` ${(saturation || 0).toFixed(0)}%,` +
      ` ${(lightness || 0).toFixed(0)}%`;
    return hslColor;
  }

  /**
   * Get HSL color value by name.

   * Example:
   * ```typescript
   * getHslColorValueByName('std-color-1'); // 173, 49%, 79%
   * ```
   */
  getHslColorValueByName(name: string): string | undefined {
    const color = this.getHslColorSetByName(name);
    if (!color) {
      return;
    }
    return this.getHslColorSetValue(color);
  }

  // TODO: move to new service override BreakpointObserver class
  getDeviceTypeByBreakpoint(): DeviceType {
    if (this.isMobileSmallScreen()) {
      return 'mobile';
    } else if (this.isTablet()) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  public getActiveTheme(): Observable<ThemeList> {
    return this.http.get(API_URL.theme_active);
  }

  insertCssTag(id: string, newStyleTag: HTMLStyleElement): void {
    const styleEl = document.getElementById(id);
    const headEl = document.getElementsByTagName('head');
    if (styleEl) {
      this.renderer.removeChild(headEl.item(0), styleEl);
    }
    this.renderer.appendChild(headEl.item(0), newStyleTag);
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  isMobileSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }

  isTabletOrMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)']);
  }

  isTablet() {
    return this.breakpointObserver.isMatched('(max-width: 1200px)');
  }

  setThemeList(theme: ThemeList) {
    this.themeConfigs = theme;
    // ! Note: d3.hsl() is used to parse string to HSL object.
    // ! If saturation (s) is 0, hue (h) will be NaN.
    this.sourceColors = {
      primary: d3.hsl(this.themeConfigs.primary_color),
      secondary: d3.hsl(this.themeConfigs.secondary_color),
    };
    this.setTheme.next(this.themeConfigs);
    this.applyColorsToCssVar();
    this.applyUtilStyleClasses();
  }

  themeSetting() {
    this.setThemeList(this.themeConfigs);
  }
}

export interface HslColorSet {
  name: string;
  source: string;
  hue?: number;
  saturation?: number;
  lightness?: number;
  interpretMode?: 'relative' | 'absolute';
}
