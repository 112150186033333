import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DrfList } from '@shared/models/drf-response.models';
import {
  AdvanceFilterDisplay,
  GeneralMemoParams,
  MemoAcknowledgmentListFilterParams,
  MemoAcknowledgmentListItem,
  MemoAnnouncement,
  MemoAnnouncementPatch,
  MemoAttachment,
  MemoLoaPreview,
  MemoPermission,
  MemoPermissionListParams,
  MemoPermissionPayload,
  MemoRecentSearch,
  MemoRecentSearchParams,
  ModifiedMemoRecentSearch,
} from '@shared/models/memo.model';
import * as _ from 'lodash';
import {
  asyncScheduler,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '@shared/service/api.constant';
import { UploadMemoTemplateDetail } from '@shared/models/upload-memo-template.model';
import { Marker } from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import {
  CommentList,
  DuplicateMemoDetail,
  MemoDetail,
  MemoDetailPatch,
  MemoListDetail,
  ReferencesDetail,
  UploadMemo,
} from '../model/memo.model';
import { UploadMemoPayload } from '../model/template.model';
import { WIDGET_SUBTYPE_KEYS } from './upload-memo.constants';
import { getTransformedMarkerByVersion } from './upload-memo.functions';
import { FolderPermission } from '../../folder/folder.model';
import { DepartmentDisplay } from '@shared/models/user.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  password: string | null;
  baseUrl = environment.baseUrl;

  private setFormat = new Subject();
  inputDateFormat = this.setFormat.asObservable();

  private _getPermissionsParamsCache: Map<
    string,
    Observable<DrfList<MemoPermission>>
  > = new Map();

  constructor(
    protected http: ApiService,
    private httpClient: HttpClient,
  ) {}

  setPassword(accessKey: string) {
    this.password = accessKey;
  }

  getPassword() {
    return this.password;
  }

  clearHeaderPassword() {
    this.password = null;
  }

  get headers(): HttpHeaders {
    const headers = this.http.requestHeader();
    headers.append('Memo-Access-Key', this.password || '');
    return headers;
  }

  addPermission(payload: MemoPermissionPayload): Observable<unknown> {
    return this.http.post(API_URL.memo_permissions, payload);
  }

  getNoAckDepartments(
    memoId: number,
  ): Observable<DepartmentDisplay[]> {
    return this.http.get(
      API_URL.memos + `${memoId}/no-ack-depts/`,
      {},
      this.headers,
    );
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }): any {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get<{ count: number }>(
      API_URL.my_task + 'badge/',
      params,
    );
  }

  getGeneralMemoCount(data?: {
    [k: string]: string | null | undefined;
  }): Observable<{ count: number }> {
    return this.http.get(API_URL.general_memo + 'count/', data);
  }

  getWaitingAnnouncementCount(data?: {
    [k: string]: string | null | undefined;
  }): Observable<{ count: number }> {
    return this.http.get(
      API_URL.announcement + 'waiting-count/',
      data,
    );
  }

  // RealMemo
  getGeneralMemoList(
    params?: GeneralMemoParams,
  ): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  getMemoListCSV(data: any) {
    // url นี้จะไม่ return file อีกแล้ว แต่จะ return celery task id มาแทน
    return this.http.get(API_URL.general_memo + 'excel/', data);
  }

  getMemoFiles(data: any) {
    return this.http.get(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoLoaPreview(id: number): Observable<MemoLoaPreview[]> {
    return this.http.get(
      API_URL.memos + id.toString() + '/loa-preview/',
      {},
      this.headers,
    );
  }

  getMemoReferenceById(id: number): Observable<ReferencesDetail[]> {
    return this.http.get<ReferencesDetail[]>(
      API_URL.memos + id + '/references/',
      {},
      this.headers,
    );
  }

  removeMemoReference(id: number, data: any) {
    return this.http.post(
      API_URL.memos + id + '/remove-references/',
      data,
    );
  }

  removePermission(permissionId: number): Observable<unknown> {
    return this.http.delete(
      API_URL.memo_permissions + permissionId + '/',
    );
  }

  // Action
  createMemo(data: UploadMemoPayload): Observable<MemoDetail> {
    return this.http.post(API_URL.memos, data);
  }

  updateMemo(id: number, data: any): Observable<MemoDetail> {
    return this.http.patch(API_URL.memos + id + '/', data);
  }

  updateAdminCanView(
    id: number,
    isCanView: boolean,
  ): Observable<MemoDetail> {
    return this.http.post(API_URL.memos + id + '/admin-manage/', {
      allow_admin_manage: isCanView,
    });
  }

  updateAnnouncement(
    id: number,
    data: Partial<MemoAnnouncementPatch>,
  ): Observable<MemoAnnouncement> {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
      this.headers,
    );
  }

  publishMemo(id: number, data?: any) {
    return this.http.post(API_URL.memos + id + '/publish/', data);
  }

  extendMemo(id: number, data: any) {
    return this.http.post(
      API_URL.memos + id + '/extend/',
      data,
      this.headers,
    );
  }

  reviseMemo(id: number) {
    return this.http.post(
      API_URL.memos + id + '/revise/',
      {},
      this.headers,
    );
  }

  recallMemo(id: number) {
    return this.http.post(
      API_URL.memos + id + '/recall/',
      {},
      this.headers,
    );
  }

  approveMemo(id: number, data?: any) {
    return this.http.post(
      API_URL.memos + id + '/approve/',
      data,
      this.headers,
    );
  }

  rejectMemo(id: number, data?: any) {
    return this.http.post(
      API_URL.memos + id + '/reject/',
      data,
      this.headers,
    );
  }

  terminateMemo(id: number, data?: any) {
    return this.http.post(
      API_URL.memos + id + '/terminate/',
      data,
      this.headers,
    );
  }

  approveMultiMemos(data?: any): any {
    return this.http.post(
      API_URL.memos + 'multi-approve/',
      data,
      this.headers,
    );
  }

  rejectMultiMemos(data?: any): any {
    return this.http.post(
      API_URL.memos + 'multi-reject/',
      data,
      this.headers,
    );
  }

  terminateMultiMemos(data?: any): any {
    return this.http.post(
      API_URL.memos + 'multi-terminate/',
      data,
      this.headers,
    );
  }

  downloadMemo(id: number): Observable<Blob> {
    return this.http.postResponseBlob(
      API_URL.memos + id + '/download-pdf/',
      {},
      this.headers,
    );
  }

  downloadMemoObserveHeader(
    id: number,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      this.baseUrl + API_URL.memos + id + '/download-pdf/',
      {},
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }

  getMemoDetail(id: number, params?: any): Observable<MemoDetail> {
    return this.http.get(
      API_URL.memos + id + '/',
      params,
      this.headers,
    );
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getPermissions(
    params?: MemoPermissionListParams,
    caching: 'no-cache' | 'cache' = 'no-cache',
  ): Observable<DrfList<MemoPermission>> {
    const paramsString = JSON.stringify(params || {});
    let permissionApiCall =
      this._getPermissionsParamsCache.get(paramsString);
    if (caching === 'cache' && permissionApiCall) {
      return permissionApiCall;
    }
    const source = new Subject<
      MemoPermissionListParams | undefined
    >();
    source.pipe(
      tap({
        subscribe: () => {
          setTimeout(() => {
            // cache for 30s after happening subscribe
            source.complete();
            this._getPermissionsParamsCache.delete(paramsString);
          }, 30000);
        },
      }),
    );
    permissionApiCall = source.pipe(
      startWith(params),
      switchMap((params) =>
        this.http.get<DrfList<MemoPermission>>(
          API_URL.memo_permissions,
          params,
        ),
      ),
      shareReplay(1, undefined, asyncScheduler),
      take(1),
    );
    this._getPermissionsParamsCache.set(
      paramsString,
      permissionApiCall,
    );
    return permissionApiCall;
  }

  getHistoryLogCSV(data: any) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id: any) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  // Upload Blob

  uploadBlob(id: any, data: any) {
    return this.http.patch(API_URL.upload_memo_blob + id + '/', data);
  }

  uploadMultiBlob(id: any, data: any) {
    return this.http.patch(
      API_URL.upload_memo_blob_multi + id + '/',
      data,
    );
  }

  removeBlob(data: any) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  // Comment

  getCommentList(params: any): Observable<CommentList[]> {
    return this.http.get(API_URL.memo_comment, params);
  }

  uploadAttachmentComment(id: number, data: any) {
    return this.http.post(
      API_URL.memo_comment + id + '/bulk-create-attachments/',
      data,
    );
  }

  createNewComment(data: any): Observable<CommentList> {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data: any) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id: any) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  // Attachment
  getMemoAttachment(params?: {
    [type: string]: string;
  }): Observable<MemoAttachment[]> {
    return this.http.get<MemoAttachment[]>(
      API_URL.memo_attachment,
      params,
    );
  }

  removeMemoAttachment(id: any) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  uploadMemoAttachment(data: any) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  downloadMemoAttachment(id: any, data?: any) {
    return this.http.post(
      API_URL.memo_attachment + id + '/download/',
      data,
    );
  }

  printFile(url: any): Observable<Blob> {
    return this.http.printFile(url);
  }

  setInputDate(date: any) {
    this.setFormat.next(date);
  }

  getMemoRevised(params: any) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledges(
    params?: MemoAcknowledgmentListFilterParams,
  ): Observable<DrfList<MemoAcknowledgmentListItem>> {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data: MemoAcknowledgmentListFilterParams) {
    return this.http.getBlob<Blob>('/api/acknowledges/excel/', data);
  }

  updateMemoEmailSetting(id: any, data: any) {
    return this.http.post(
      API_URL.memos + id + '/email-setting/',
      data,
    );
  }

  // trash
  getFinishedMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.finished_memo,
      params,
    );
  }

  getFinishedMemoCount(data?: {
    [k: string]: string | null | undefined;
  }): Observable<{ count: number }> {
    return this.http.get(API_URL.finished_memo + 'count/', data);
  }

  bulkTrashMemo(data: any) {
    return this.http.post(API_URL.bulk_trash_memo, data);
  }

  bulkUntrashMemo(data: any) {
    return this.http.post(API_URL.bulk_untrash_memo, data);
  }

  bulkPermanentlyDeleteMemo(data: any) {
    return this.http.post(API_URL.bulk_permanently_delete_memo, data);
  }

  getFinishedMemoListCSV(data: any) {
    return this.http.get(API_URL.finished_memo + 'excel/', data);
  }

  exportMemoToGDrive(data: any) {
    return this.http.post<{ task_id: string }>(
      API_URL.finished_memo + 'export-gdrive/',
      data,
    );
  }

  getNdidCredit() {
    return this.http.get('/api/ndid/credit/');
  }

  getRecentSearch(
    params?: MemoRecentSearchParams,
    options = { modifyAdvanceSearch: true },
  ): Observable<MemoRecentSearch[] | ModifiedMemoRecentSearch[]> {
    return this.http
      .get<MemoRecentSearch[]>(
        API_URL.general_memo + 'recent-search/',
        params,
      )
      .pipe(
        map((res) => {
          if (!options.modifyAdvanceSearch) {
            return res;
          }
          return res.map((search) => {
            return {
              ...search,
              advance_filter_display:
                search.advance_filter_display?.reduce(
                  (obj, advSearch) => {
                    obj[advSearch.key] = advSearch;
                    return obj;
                  },
                  {} as {
                    [k: string]: AdvanceFilterDisplay | undefined;
                  },
                ) || {},
            };
          }) as ModifiedMemoRecentSearch[];
        }),
      );
  }

  getThaicomCredit() {
    return this.http.get('/api/thaicom/credit/');
  }

  /**
   * Remove unnecessary fields in marker for payload's size reduction.
   * This method return new marker without reference pointer.
   */
  refineMarkerForSave(marker: Marker): Marker {
    const clonedMarker = _.cloneDeep(marker);
    delete clonedMarker.apparentPageMaps;
    delete clonedMarker.apparentPageGroups;
    const identityKeys = [...WIDGET_SUBTYPE_KEYS];
    identityKeys.forEach((identityKey) => {
      clonedMarker[identityKey]?.forEach((identity) => {
        delete identity.screenCoordinate;
      });
    });
    return clonedMarker;
  }

  refineLoa(memo: MemoDetail): MemoDetailPatch {
    const memoClone: MemoDetailPatch = {
      ...memo,
      loa_group: memo.loa_group?.id || null,
    };
    return memoClone;
  }

  removeAllMemoAttachment(memo_id: any) {
    return this.http.post('/api/memo-attachments/remove-all/', {
      memo_id,
    });
  }

  addToFolder(id: number, data: { folder: number }): Observable<any> {
    return this.http.post<any>(
      API_URL.memos + id + '/add-to-folder/',
      data,
      this.headers,
    );
  }

  updateNote(id: number, data: { note: string }): Observable<any> {
    return this.http.post<any>(API_URL.memos + id + '/note/', data);
  }

  /**
   * Transform markers object in an upload memo by their version.
   * @param data The upload memo or upload memo template that want to transform marker inside.
   * @returns The upload memo or upload memo template that is the same pointer with the argument.
   */
  transformMarkerByVersion(
    data: UploadMemo | UploadMemoTemplateDetail,
  ): UploadMemo | UploadMemoTemplateDetail {
    const transformedMarkers = getTransformedMarkerByVersion(
      data.signature_position,
      data.widget_version ?? '1.0',
    );
    data.signature_position = transformedMarkers;
    return data;
  }

  sendReminder(id: number, data: any) {
    return this.http.post(
      API_URL.memos + id + '/send-reminder/',
      data,
    );
  }

  expiryDateMemo(id: number, data: any) {
    return this.http.post(API_URL.memos + id + '/expiry-date/', data);
  }

  duplicateMemo(
    id: number,
    data: DuplicateMemoDetail,
  ): Observable<{ id: number }> {
    return this.http.post(
      API_URL.memos + id + '/duplicate/',
      data,
      this.headers,
    );
  }

  addMemoReference(id: number, data: { [type: string]: string }) {
    return this.http.post(
      API_URL.memos + id + '/add-references/',
      data,
    );
  }

  updatePermission(
    id: number,
    payload: MemoPermissionPayload,
  ): Observable<unknown> {
    return this.http.patch(
      API_URL.memo_permissions + id + '/',
      payload,
    );
  }

  getFolderMemberViaMemo(
    id: number,
    params: { folder: number },
  ): Observable<FolderPermission[]> {
    return this.http.get(
      API_URL.memos + id + '/folder-members/',
      params,
    );
  }

  uploadEmissionEvidenceFile(id: number, data: any) {
    return this.http.patch(
      '/api/evidence-attachment/' + id + '/',
      data,
    );
  }

  removeEvidenceFile(purchase_id: number) {
    return this.http.delete(
      '/api/evidence-attachment/' + purchase_id + '/',
    );
  }

  getDownloadDetail(id: number) {
    return this.http.get(
      API_URL.memos + id + '/download-detail/',
      {},
      this.headers,
    );
  }

  downloadDetail(
    id: number,
    mode: 'merge' | 'split',
    data: any,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      this.baseUrl +
        API_URL.memos +
        id +
        '/download-detail/' +
        mode +
        '/',
      data,
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }
}
