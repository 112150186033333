import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ShowMemoPasswordPopup } from '../../store/memo/memo.actions';
import { MemoPasswordPopUp } from 'src/app/store/memo/memo.model';

@Injectable()
export class MemoPasswordInterceptor implements HttpInterceptor {
  storedPassword: string | null = null;
  storedMemoId?: string | null = null;

  private store = inject(Store);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const memoIdFromUrl = request.url
      .match(/\/(\d+)+\/?/g)
      ?.map((id) => id.replace(/\//g, ''))
      ?.pop();

    if (!localStorage.getItem('currentUser')) {
      this.storedPassword = '';
      this.storedMemoId = '';
    }

    // Skip password if memoId matches the stored one
    if (this.storedPassword && this.storedMemoId === memoIdFromUrl) {
      return next
        .handle(this.addPasswordHeader(request, this.storedPassword))
        .pipe(
          catchError((err: any) => {
            if (
              err.status === 403 &&
              this.isPasswordError(err) &&
              request.url.includes('memos')
            ) {
              return this.handlePasswordError(err, request, next);
            }
            return throwError(() => err);
          }),
        );
    }

    // Incorrect password
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (
          err.status === 403 &&
          this.isPasswordError(err) &&
          request.url.includes('memos')
        ) {
          return this.handlePasswordError(err, request, next);
        }
        return throwError(() => err);
      }),
    );
  }

  private handlePasswordError(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const memoIdFromUrl = request.url
      .match(/\/(\d+)+\/?/g)
      ?.map((id) => id.replace(/\//g, ''))
      ?.pop();

    // Dispatch action to display popup for entering a new password
    this.store.dispatch(
      new ShowMemoPasswordPopup(
        err.error.detail === 'REQT_INVALID_PASSWORD',
        memoIdFromUrl ? +memoIdFromUrl : null,
      ),
    );

    return this.store
      .select((state) => {
        return state.memoPasswordPopUp;
      })
      .pipe(
        filter((payload: MemoPasswordPopUp) => !payload.show),
        take(1),
        switchMap((payload: MemoPasswordPopUp) => {
          if (payload.password) {
            this.storedPassword = payload.password;
            this.storedMemoId = memoIdFromUrl;
            return this.intercept(
              this.addPasswordHeader(request, payload.password),
              next,
            );
          } else {
            return throwError(() => err);
          }
        }),
      );
  }

  private isPasswordError(err: any): boolean {
    return ['REQT_INVALID_PASSWORD', 'INVALID_PASSWORD'].includes(
      err.error.detail,
    );
  }

  private addPasswordHeader(
    request: HttpRequest<any>,
    password: string,
  ) {
    return request.clone({
      setHeaders: {
        'Memo-Access-Key': password,
      },
    });
  }
}
