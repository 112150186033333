<ngx-file-drop class="file-drop pointer"
    dropZoneLabel="Browse or Drop files here"
    (click)="filePhoto.click()"
    (onFileDrop)="onFileDrop($event)">
  <ng-template ngx-file-drop-content-tmp>
    <div class="d-flex flex-column align-items-center gap-1">
      <svg class="pointer icon-btn-svg ">
        <use href="assets/images/icons/apps/window-arrow-up.svg#window-arrow-up"
            stroke="#707485"
            height="20"
            width="20">
        </use>
      </svg>
      <span class="fw-bold fs-base-l4 fs-base-md-l2 text-blue-tang ps-2">
          {{ 'MEMOS.Choose a file or drag & drop it here' | translate }}
      </span>
      <div class="fs-base-l5 fs-base-md-l4 text-gray-neutrals px-3 text-center">
        {{ 'MEMOS.JPG, PNG formats, up to 45 MB' |translate }}
      </div>
      <div class="fs-base-l5 fs-base-md-l4 px-3 text-center text-red-strong">
        {{ 'SELECT-SIGN-METHOD.Recommended size' | translate }} 340*80px
      </div>
      <div class="my-1">
        <button class="btn btn-h btn-std-2ry fs-base-l4 fw-semibold mx-1 py-base-l8 px-base-l4 btn-width"
            type="button">
          {{ 'COMMON.UPLOAD-FILE' |translate }}
        </button>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
<input type="file"
    accept="image/png, image/jpeg"
    class="d-none"
    (change)="filePhotoChangeEvent($event)"
  #filePhoto>

<div class="d-flex justify-content-end mt-4 text-end"
    [ngClass]="{'pt-2 mb-btn-bottom': !isModal}">
  <button class="btn btn-std btn-std-outline fs-base-l0 fw-semibold justify-content-center"
      [ngClass]="{'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': isModal}"
      type="button"
      [ladda]="isLoading"
      (click)="back.emit()">
    {{ cancelText | translate }}
  </button>
</div>